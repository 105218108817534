import Project from './Project';

const projects = [
  { title: 'Project One', description: 'Description of project one.', tags: ['React', 'JavaScript'], year: '2021-2022' },
  { title: 'Project Two', description: 'Description of project two.', tags: ['HTML', 'CSS'], year: '2022' },
  { title: 'Project Three', description: 'Description of project three.', tags: ['Node.js', 'Express'], year: '2020-2021' },
  { title: 'Project Two', description: 'Description of project two.', tags: ['HTML', 'CSS'], year: '2022' },
  { title: 'Project Three', description: 'Description of project three.', tags: ['Node.js', 'Express'], year: '2020-2021' },
];

function ProjectList() {


  return (
    <div className="project-list">
      <h2 style={{textAlign: 'center'}} >Projects</h2>
      {projects.map((project, index) => (
        <Project key={index} project={project} />
      ))}
    </div>
  );
}

export default ProjectList;
