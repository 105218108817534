import React, { useState } from 'react';
import { ReactComponent as PortfolioIcon } from '../assets/icons/portfolio-svgrepo-com.svg';
import { ReactComponent as SQLIcon } from '../assets/icons/sql-file-format-svgrepo-com.svg';
import { ReactComponent as BookIcon } from '../assets/icons/education-book-learn-school-library-bookmark-svgrepo-com.svg';
import { ReactComponent as OrientationIcon } from '../assets/icons/switch-orientation-button-svgrepo-com.svg';
import {ReactComponent as CodeIcon} from '../assets/icons/code-file-svgrepo-com.svg'

function Sidebar() {
  const [isVertical, setIsVertical] = useState(true);

  const toggleNavBar = () => {
      setIsVertical(!isVertical);
  };
// const sidebarStyle = {
//     backgroundColor: 'rgba(44, 44, 44)', // Using rgba for background with opacity
//     borderRadius: '20px',
//     width: '200px',
//     heigth: '800px',
//     marginBottom: '40px',
//     padding: '30px',
//     boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
//     position: 'fixed',
//     display: 'flex',
// };

  return (
    // <div id="sidebar" className="sidebar" style={ sidebarStyle}>
    //     <a href="#" className="sidebar-item"><div class="icon portfolio-icon"></div></a>
    //     <a href="#" className="sidebar-item"><div class="icon querybuilder-icon"></div></a>
    //     <a href="#" className="sidebar-item"><div class="icon elementlibrary-icon"></div></a>
    //     <button className="orientation-toggle">
    //         <div className="icon orientation-icon"></div>
    //     </button>
    // </div>
    <div className={isVertical ? "navbar navbar-vertical" : "navbar navbar-horizontal"}>
        <div className="nav-item">
          <PortfolioIcon className="icon" />
        </div>
        <div className="nav-item">
         <SQLIcon className="icon" />
        </div>
        <div className="nav-item">
          <BookIcon className="icon" />
        </div>
        <div className="nav-item">
          <CodeIcon className="icon" />
        </div>
        <button onClick={toggleNavBar} className="toggle-btn nav-item">        
            <OrientationIcon className="icon" />
        </button>
    </div>
  );
}

export default Sidebar;
