import React from 'react';

const CanvasBackground = () => {
  return (
    // <iframe
    //   src="canvas-animation.html"
    //   style={{
    //     position: 'fixed',
    //     top: 0,
    //     left: 0,
    //     width: '100%',
    //     height: '100%',
    //     border: 'none',
    //     zIndex: -1,
    //     overflow: 'hidden',
    //   }}
    //   title="Canvas Animation"
    // />
    <div></div>
  );
};

export default CanvasBackground;
