import React from 'react';

function Header() {
  return (
    <header className="headerCard">
      <h1 className="headerTitle">Jogaila Janušas</h1>
      <p className="headerDescription">Welcome to my portfolio. Some words:
      Lorem ipsum odor amet, consectetuer adipiscing elit. Maximus vitae finibus nulla facilisi sem habitasse elementum lorem. Tortor augue diam tellus lacinia leo nascetur est faucibus. Conubia semper a mollis himenaeos suspendisse ultrices mi. Vivamus penatibus morbi vestibulum, suspendisse placerat torquent donec tempor efficitur. Tristique dui donec velit pulvinar, vivamus morbi. Luctus inceptos lectus per, litora parturient accumsan a. Duis nostra interdum urna aptent aptent etiam. Metus ipsum ad platea sagittis scelerisque eleifend sit nec.
      </p>
    </header>
  );
}

export default Header;
