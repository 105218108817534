import React from 'react';

function Project({ project, showDetails }) {
  const projectCardStyle = {
    backgroundColor: 'rgba(44, 44, 44, 0.7)', // Using rgba for background with opacity
    borderRadius: '20px',
    width: '40rem',
    marginBottom: '40px',
    display: 'flex',
  };

  const projectImgStyle = {
    width: '7rem',
    margin: '10px',
  };

  const projectDetailsStyle = {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '8px',
  };

  const projectTitleStyle = {
    marginTop: '14px',
    color: '#fff', // Ensure text is fully opaque
  };

  const projectDateStyle = {
    marginLeft: '30px',
    color: 'lightBlue',
    fontSize: '1rem',
  };

  const projectDescriptionStyle = {
    color: '#ddd', // Ensure description text is fully opaque
  };

  const projectTagsStyle = {
    display: 'flex',
    marginBottom: '10px',
  };

  const projectTagStyle = {
    backgroundColor: '#1a73e8',
    padding: '5px 10px',
    borderRadius: '20px',
    fontSize: '14px',
    color: '#fff',
    marginRight: '5px',
  };

  return (
    <div className="projectCard" style={projectCardStyle}>
      <img
        className="projectImg"
        style={projectImgStyle}
        src="https://media.dev.to/cdn-cgi/image/width=1080,height=1080,fit=cover,gravity=auto,format=auto/https%3A%2F%2Fdev-to-uploads.s3.amazonaws.com%2Fuploads%2Farticles%2Fjplnld3gptkremcqom5q.png"
        alt="Project Thumbnail"
      />
      <div style={projectDetailsStyle}>
        <div>
          <h3 style={projectTitleStyle}>
            {project.title}
            <span className="year" style={projectDateStyle}>
              {project.year}
            </span>
          </h3>
        </div>
          <div>
            <p style={projectDescriptionStyle}>{project.description}</p>
            <div className="tags" style={projectTagsStyle}>
              {project.tags.map((tag, index) => (
                <span key={index} className="tag" style={projectTagStyle}>
                  {tag}
                </span>
              ))}
            </div>
          </div>
      </div>
    </div>
  );
}

export default Project;
