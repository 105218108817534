import React, { useEffect, useState, useRef } from 'react';
import { ReactComponent as PlayIcon } from '../../assets/icons/play-svgrepo-com.svg';
import { ReactComponent as PauseIcon } from '../../assets/icons/pause-svgrepo-com.svg';
import { ReactComponent as RestartIcon } from '../../assets/icons/restart-svgrepo-com.svg';
import { ReactComponent as RotateIcon} from '../../assets/icons/rotate-right-svgrepo-com.svg';

const CanvasController = () => {
  const iframeRef = useRef(null);
  const [options, setOptions] = useState({
    len: 60,
    count: 40,
    baseTime: 3,
    angle: Math.PI * 2 / 6, // Default angle is 60 degrees
  });
  const [isStep2, setIsStep2] = useState(false);

  useEffect(() => {
    const iframe = iframeRef.current;

    const handleIframeLoad = () => {
      if (iframe && iframe.contentWindow && iframe.contentWindow.updateOpts) {
        iframe.contentWindow.updateOpts(options);
      }
    };

    if (iframe) {
      iframe.addEventListener('load', handleIframeLoad);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener('load', handleIframeLoad);
      }
    };
  });

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow && iframe.contentWindow.updateOpts) {
      iframe.contentWindow.updateOpts(options);
    }
  }, [options]);

  const handleStart = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow && iframe.contentWindow.startAnimation) {
      iframe.contentWindow.startAnimation();
    }
  };

  const handleStop = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow && iframe.contentWindow.stopAnimation) {
      iframe.contentWindow.stopAnimation();
    }
  };

  const handleReset = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow && iframe.contentWindow.resetAnimation) {
      iframe.contentWindow.resetAnimation();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOptions({
      ...options,
      [name]: parseFloat(value),
    });
    handleReset();
  };

  const increaseValue = (name) => {
    setOptions({
      ...options,
      [name]: options[name] + 1, // Increase the value by 1 (you can adjust this)
    });
  };

  const decreaseValue = (name) => {
    setOptions({
      ...options,
      [name]: Math.max(options[name] - 1, 0), // Decrease the value by 1, ensuring it doesn't go below 0 (you can adjust this)
    });
  };

  const increaseAngle = () => {
    setOptions({
      ...options,
      angle: options.angle + (Math.PI / 36), // Increase by 5 degrees
    });
    handleReset();
  };

  const decreaseAngle = () => {
    setOptions({
      ...options,
      angle: options.angle - (Math.PI / 36), // Decrease by 5 degrees
    });
    handleReset();
  };

  const toggleStepFunction = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow && iframe.contentWindow.toggleStepFunction) {
      iframe.contentWindow.toggleStepFunction();
    }
    setIsStep2(!isStep2);
  };

  const increaseRad = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow && iframe.contentWindow.incrementRad) {
      iframe.contentWindow.incrementRad();
    }
  };

  return (
    <div>
      <iframe
        id="animation-iframe"
        ref={iframeRef}
        src="canvas-animation.html"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 'none',
          zIndex: -1,
          overflow: 'hidden',
        }}
        title="Canvas Animation"
      />
        <div className="bar">
          <button onClick={handleStart} className="iconBtn"> <PlayIcon className="icon" /></button>
          <button onClick={handleStop} className="iconBtn"><PauseIcon className="icon"  /></button>
          <button onClick={handleReset} className="iconBtn"><RestartIcon className="icon"  /></button>
          <button onClick={increaseRad} className="iconBtn"><RotateIcon className="icon" /></button>
          <button onClick={toggleStepFunction} className="textBtn">
            {isStep2 ? 'Use Dot mode' : 'Use Line mode'}
          </button>
          <div className="controlSection">
            <p className="variableName">Length</p>
            <div className="valueControls">
              <button className="controlBtn" onClick={() => increaseValue('len')}>+</button>
              <input
                type="number"
                name="len"
                value={options.len}
                onChange={handleInputChange}
                className="valueDisplay"
              />
              <button className="controlBtn" onClick={() => decreaseValue('len')}>-</button>
            </div>
          </div>

          <div className="controlSection">
            <p className="variableName">Count</p>
            <div className="valueControls">
              <button className="controlBtn" onClick={() => increaseValue('count')}>+</button>
              <input
                type="number"
                name="count"
                value={options.count}
                onChange={handleInputChange}
                className="valueDisplay"
              />
              <button className="controlBtn" onClick={() => decreaseValue('count')}>-</button>
            </div>
          </div>

          <div className="controlSection">
            <p className="variableName">Base Time</p>
            <div className="valueControls">
              <button className="controlBtn" onClick={() => increaseValue('baseTime')}>+</button>
              <input
                type="number"
                name="baseTime"
                value={options.baseTime}
                onChange={handleInputChange}
                className="valueDisplay"
              />
              <button className="controlBtn" onClick={() => decreaseValue('baseTime')}>-</button>
            </div>
          </div>

          <div className="controlSection">
            <p className="variableName">Angle</p>
            <div className="valueControls">
              <button className="controlBtn" onClick={increaseAngle}>+</button>
              <input
                type="number"
                name="angle"
                value={(options.angle * 180 / Math.PI).toFixed(2)} // Display in degrees
                onChange={handleInputChange}
                className="valueDisplay"
                readOnly
              />
              <button className="controlBtn" onClick={decreaseAngle}>-</button>
            </div>
          </div>
        </div>
    </div>
  );
};

export default CanvasController;
